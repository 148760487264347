export type EnvironmentVariables = {
  apiUri: string
  siteId: string
  siteFqdn: string
  credReference: string
}

export const useEnvironmentVariables = (): EnvironmentVariables => {
  const checkedApiURI =
    ((window as any).API_URI as string) === '%SUBAPIURI%'
      ? 'https://localhost:44306'
      : ((window as any).API_URI as string)

  const checkedSiteFqdn =
    ((window as any).SITE_FQDN as string) === '%SUBSITEFQDN%'
      ? 'site.fwd.captivefi.com'
      : ((window as any).SITE_FQDN as string)

  const checkedCredRef =
    ((window as any).SITE_CRED_REFERENCE as string) === '%SUBSITECREDREFERENCE%'
      ? 'FWDHOSP-FWD'
      : ((window as any).SITE_CRED_REFERENCE as string)

  const checkedSiteId =
    ((window as any).SITE_ID as string) === '%SUBSITEID%'
      ? 'f034f9d1-49cf-4878-9717-0ec0c2e7bd30'
      : ((window as any).SITE_ID as string)

  const EnvironmentVars: EnvironmentVariables = {
    apiUri: checkedApiURI,
    siteId: checkedSiteId,
    siteFqdn: checkedSiteFqdn,
    credReference: checkedCredRef,
  }
  return EnvironmentVars
}
